
.site-header {
	background: #333;

	.dropdown-toggle {
		background-color: transparent !important;
		color: white;

		&:hover {
			background-color: rgba(255, 255, 255, 0.2) !important;
		}
	}
}

.header-brand-link {
	text-decoration: none;
}

.header-brand-text {
	color: #bbb;
	font-size: 26px;
	font-weight: 300;

	strong {
		color: #fff;
		font-weight: 700;
	}
}

.header-authenticated .header-brand-text {
	@media (max-width: 440px) {
		display: none;
	}
}

.header-brand-logo {
	filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.4));
}

$bling-height: 5px;

.header-bling {
	height: $bling-height;
	overflow: hidden;
}

.header-bling-bg {
	$height: 300px;

	--easing: linear;

	animation: animateBg 10s var(--easing) alternate infinite;
	background-image: linear-gradient(5deg, $brand-primary-200 45%, tint-color($brand-primary-100, 80%) 50%, $brand-primary-200 55%);
	height: $height;
	position: relative;
	top: 0px;

	@include media-breakpoint-up(md) {
		--easing: ease-in-out;
	}

	@keyframes animateBg {
		0% { top: 0px; }
		100% { top: -$height + $bling-height; }
	}
}
