$enable-dark-mode: false;

$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-base:       $font-family-sans-serif;

$font-size-h1: 2.7rem;
$font-size-h2: 2.1rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.2rem;
$font-size-h5: 1.1rem;
$font-size-h6: 1rem;

$headings-line-height:   1.2;
