@use "sass:map";

.form-control:focus-within {
	color: $input-focus-color;
	background-color: $input-focus-bg;
	border-color: $input-focus-border-color;
	outline: 0;
	@if $enable-shadows {
		@include box-shadow($input-box-shadow, $input-focus-box-shadow);
	} @else {
		// Avoid using mixin so we can pass custom focus shadow properly
		box-shadow: $input-focus-box-shadow;
	}

	@each $state, $data in $form-validation-states {
		$color: map.get($data, "color");
		$focus-box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($color, $input-btn-focus-color-opacity);

		@include form-validation-state-selector($state) {
			border-color: $color;
			box-shadow: $focus-box-shadow;
		}
	}
}
