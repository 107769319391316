
.site-footer {
	background: linear-gradient(to bottom, $footer-from, $footer-to);

	a:not(.btn) {
		color: $footer-link;
	}
}

.footer-container {
	padding-top: 25px;
	padding-bottom: 80px;
}
