
$brand-primary-100:   #ffd31a;
$brand-primary-200:   #f0ad4e;
$brand-primary-400:   #ff3f00;

$brand-orange:       $brand-primary-200;
$brand-red:          $brand-primary-400;

$brand-pink:         #e53170;
$brand-pink-100:     tint-color($brand-pink, 20%);
$brand-pink-200:     shade-color($brand-pink, 20%);

$brand-green:        #1d8719;

$link-blue-light:    #82c8e4;
$link-blue-dark:     #2d94bd;

$white:              #fff;
$gray-100:           #f9f9f9;
$gray-200:           #ececec;
$gray-300:           #e2e2e2;
$gray-400:           #d4d4d4;
$gray-500:           #b5b5b5;
$gray-600:           #757575;
$gray-700:           #505050;
$gray-800:           #3a3a3a;
$gray-900:           #252525;
$black:              #000;
