@use "sass:map";

a {
	text-underline-offset: 0.2rem;
}

// jQuery validation
.input-validation-error {
	border: 1px solid red;
}

// My stuff
.flush-content {
	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.list-unstyled {
	li {
		padding-bottom: 4px;
	}
}

.list-unstyled ul {
	@include list-unstyled();
}

.hidden-xxs {
	@media (max-width: 400px) {
		display: none;
	}
}

.plain-links a:not(.btn) {
	@include plain-link;
}

.main-layout {
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	padding-top: map.get($spacers, 6);
	padding-bottom: map.get($spacers, 7);
}

.section-layout {
	display: flex;
	flex-direction: column;
	gap: map.get($spacers, 7);
}

.content-layout {
	display: flex;
	flex-direction: column;
	gap: map.get($spacers, 6);
}

.container-scrollable-xs {
	@media (max-width: breakpoint-next(xs)) {
		overflow-x: scroll;

		> * {
			min-width: 600px;
		}
	}
}

.btn {
	cursor: pointer;
}

.link-logo {
	color: $body-color !important;
}
