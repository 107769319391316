
.tools {

	code, pre {
		font-size: 14px;
	}

	input, textarea {
		font-family: $font-family-monospace;
	}
}

.maximized-editor {
	position: fixed;
	top: 0;
	left: 0;
	width: 100% !important;
	height: calc(100% - 1px) !important;
	z-index: 100;
}
