@use "sass:math";
@use "sass:map";

$font-scale: 1.2;

$font-scale-n3: 1rem * math.pow($font-scale, -3); // 0.579rem
$font-scale-n2: 1rem * math.pow($font-scale, -2); // 0.694rem
$font-scale-n1: 1rem * math.pow($font-scale, -1); // 0.833rem
$font-scale-0: 1rem * math.pow($font-scale, 0);   // 1rem
$font-scale-1: 1rem * math.pow($font-scale, 1);   // 1.2rem
$font-scale-2: 1rem * math.pow($font-scale, 2);   // 1.44rem
$font-scale-3: 1rem * math.pow($font-scale, 3);   // 1.728rem
$font-scale-4: 1rem * math.pow($font-scale, 4);   // 2.074rem
$font-scale-5: 1rem * math.pow($font-scale, 5);   // 2.488rem
$font-scale-6: 1rem * math.pow($font-scale, 6);   // 2.986rem

$h1-font-size: $font-scale-5;
$h2-font-size: $font-scale-4;
$h3-font-size: $font-scale-3;
$h4-font-size: $font-scale-2;
$h5-font-size: $font-scale-1;
$h6-font-size: $font-scale-0;

$spacer: 1rem;
$spacer-scale: 1.5;
$spacers: (
  0: 0,
  1: $spacer * math.pow($spacer-scale, -3), // 0.296rem
  2: $spacer * math.pow($spacer-scale, -2), // 0.444rem
  3: $spacer * math.pow($spacer-scale, -1), // 0.667rem
  4: $spacer * math.pow($spacer-scale, 0),  // 1rem
  5: $spacer * math.pow($spacer-scale, 1),  // 1.5rem
	6: $spacer * math.pow($spacer-scale, 2),	// 2.25rem
	7: $spacer * math.pow($spacer-scale, 3),  // 3.375rem
	8: $spacer * math.pow($spacer-scale, 4),  // 5.063rem
	9: $spacer * math.pow($spacer-scale, 5),  // 7.594rem
);

$container-padding-x: map.get($spacers, 7);

$input-btn-focus-box-shadow: none;
$form-select-focus-box-shadow: none;

@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/variables-dark";
