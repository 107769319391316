
.article-header {
	margin-bottom: 1rem;

	@media (min-width: 768px) {
		display: flex;
		justify-content: space-between;
	}
}

.article-headline {
	margin-bottom: 1rem;
}
