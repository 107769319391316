.btn-tinted {
	background-color: $btn-tinted-bg;
	border: 1px solid $btn-tinted-border;
	color: $btn-tinted-color;

	&.active,
	&:focus-visible,
	&:hover {
		background-color: $btn-tinted-bg-active;
	}
}

@mixin btn-tinted-color($normal, $active) {
	.bi {
		color: $normal;
	}

	&.active,
	&:focus-visible,
	&:hover {
		.bi {
			color: $active;
		}
	}
}

.btn-tinted-danger {
	@include btn-tinted-color($btn-tinted-color-danger, $btn-tinted-color-danger-active);
}

.btn-tinted-primary {
	@include btn-tinted-color($btn-tinted-color-primary, $btn-tinted-color-primary-active);
}

.btn-tinted-success {
	@include btn-tinted-color($btn-tinted-color-success, $btn-tinted-color-success-active);
}
