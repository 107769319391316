
.table-nomargin {
	margin: 0;
}

.table-keyvalue {
	width: 100%;

	td {
		border: none;
		padding: 4px 5px 4px 0;
	}

	.key {
		white-space: nowrap;
	}

	.value {
		white-space: normal;
		word-break: break-all;
	}

	.value a {
		display: inline-block;
	}
}
