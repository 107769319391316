#components-reconnect-modal {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: var(--bs-body-bg);
	opacity: 0.9;
	z-index: $zindex-modal;

	.show,
	.failed,
	.rejected {
		display: none;
	}

	&.components-reconnect-show,
	&.components-reconnect-failed,
	&.components-reconnect-rejected {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.components-reconnect-show .show,
	&.components-reconnect-failed .failed,
	&.components-reconnect-rejected .rejected {
		display: block;
	}
}
